import axios from 'axios';
import { isAuthenticated } from './auth'
import { isClient } from '../styles/utils'
import { JSON_URL } from '../constants';

export function fetchData(endpoint, debug, noCache) {    

    let config = {}

    if (isClient()) {
        const jwt = localStorage.getItem('jwtToken');

        if (jwt) {
            config = {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json'
                }
            }
        }
    }

    if (noCache) {
        endpoint = `${endpoint}?=${Date.now()}`
    }

    return new Promise((resolve, reject) => {
        axios.get(JSON_URL + endpoint, config)
            .then((response) => {
                if (response.data) {
                    debug && console.log(response.data)
                    resolve(response.data);
                }
            })
            .catch(error =>  {
                reject(error)
            })      
    })
}

export function postData(endpoint, data) {
    let config = {}

    // console.log(JSON_URL, endpoint, data)

    if (isClient()) {
        const jwt = localStorage.getItem('jwtToken');

        if (jwt) {
            config = {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json'
                }
            }      
        }
    }

    return new Promise((resolve, reject) => {
        axios.post(JSON_URL + endpoint, data, config)
            .then((response) => {
                // console.log('response',response);
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch(error =>  {
                // console.log('error',error);
                reject(error)
            })   
    })
}


export function checkToken() {    
    let config = {}

    if (isClient()) {
        const jwt = localStorage.getItem('jwtToken');

        if (jwt) {
            config = {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json'
                }
            }
        }
    }
        
    return new Promise((resolve, reject) => {
        axios.post(JSON_URL + 'wpapi/checkToken', {}, config)
            .then((response) => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch(error =>  {
                reject(error)
            })      
    
    })
}