import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, Footer, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';

// import DefaultPage from '../templates/default-page'

const Preview = () => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
	const postID = useSearchParam('id');

	useMount(() => {
		fetchData(`/pda/preview/${postID}`, true, true)
			.then(response => {				
				let data = response;

				setData(data)
				setPostType(data.post_type)
			})
	})

	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
			// 'page': DefaultPage,
		}
		
		const Component = templates[postType];

		return (
			<Component
				previewData={data}
				pageContext={{
					...data
				}}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

